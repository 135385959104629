/****************** General ************************/
/***************************************************/
/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider,
.separator,
#services_module.list .list_item .footer,
#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	height: 1px !important;
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	background-color: #cee2ee !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

.collection > .node {
	border-top-color: #cee2ee;
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.section_separator {
	border-bottom: 1px solid #cee2ee;
}

/* dates */
#alerts_module.list .title .date,
#events_module.cms_list .cms_date,
#feeds_module.cms_list .cms_list_item .cms_date h4,
#feeds_module.cms_entity .cms_date h4,
#jobs_module.cms_list .cms_list_item .cms_date h3,
#news_module.cms_list article time,
#news_module.cms_entity .cms_date h3,
#photoalbums_module.cms_list .cms_list_item .cms_number_of_images, /* this is not a date but styled as one */
.posts-node .posts-field.posts-field-posted p,
#video_module.cms_list .cms_list_item .cms_date,
#video_module.cms_entity .cms_date h3 {
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
	color: #222222;
	font-family: 'Open Sans', sans-serif;
}

/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: none;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #3169b4;
	font-size: 1.2rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ff0000;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	font-size: 0.87rem;
	line-height: 1.428571429;
	color: #333333;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

/****************** Accounts ************************/
/***************************************************/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 300px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #cee2ee;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #3169b4;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
	font-family: 'Open Sans', sans-serif;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ff0000;
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}


/****************** Alerts ************************/
/*************************************************/
#alerts_module.list .title h2 {
	margin-top: 0px;
}

#alerts_module_subscribe_form.form .item_divider {
	border-top: 2px solid #3169b4 !important;
	margin-bottom: 0 !important;
}

#alerts_module_subscribe_form .form_container h3 {
	padding-top: 0.75rem;
}

#alerts_module.form .field {
	padding-bottom: 15px;
}

#alerts_module.form .field small {
	display: block;
}

@media (max-width: 991px) {
	#alerts_module_subscribe_form.form .form_container {
		width: 100%;
	}

	#alerts_module.form .form_container .form_item .field,
	#alerts_module.form .form_container .form_item .field input[type="text"] {
		width: 100% !important;
		display: block;
		text-align: left;
		background: none;
	}

	#alerts_module.form .form_container .form_item .field {
		padding-left: 0px;
	}

	#alerts_module.form .form_container .form_item .label,
	#alerts_module.form .form_container .form_item.reqiuired .label {
		display: inline-block;
		text-align: left;
		width: auto;
	}

	#alerts_module.form .form_container .form_item.required .label {
		background: url("/core/public/shared/assets/images/required.gif") no-repeat right 11px;
		padding-right: 15px;
	}
}


/****************** RSS Feeds *********************/
/*************************************************/
#feeds_module.cms_list .cms_title h3,
#feeds_module.cms_list .cms_date h4 {
	margin-top: 0px;
}

#feeds_module.cms_list .abstract {
	padding: 0px;
}

/******************** Collections ******************/
/***************************************************/
.module-collections.controller-pages-collections .files-wrapper {
	margin-left: 0rem;
}

/****************** Events *********************/
/***********************************************/

#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/****************** Files *********************/
/***********************************************/
.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 1rem 0px 0px 0px;
	padding: 1rem 0px 0px 0px;
	border-top: 1px solid #cee2ee;
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
}

/* sidebar file filtering */
.right-side-col-wrapper .box.filter .filter-row .select2-container,
.right-side-col-wrapper .box.filter .filter-row #filter-keywords {
	max-width: 100%;
	width: 100% !important;
}

.right-side-col-wrapper .box.filter .filter-row.ui-form-buttons {
	padding: 0px;
}

.right-side-col-wrapper .box.filter .filter-row.ui-form-buttons .ui-form-field {
	padding: 6px;
	text-align: left;
}

.right-side-col-wrapper .box.filter .filter-row .select2-container-multi .select2-choices,
.right-side-col-wrapper .box.filter .filter-row .select2-container .select2-choice {
	border-radius: 0px;
	box-shadow: none;
	border: 1px solid #cccccc;
	background: none;
	padding: 8px 12px;
	height: auto;
}

.right-side-col-wrapper .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field .select2-input {
	margin: 0;
	padding: 0px;
}

/****************** Forums *********************/
/***********************************************/
#forums_module #posts table,
#forums_module #boards table,
#forums_module #topics table,
#forums_module #posts .post_footer {
	background: none;
	border: 0px;
}

#forums_module #boards td,
#forums_module #topics td,
#forums_module #posts td {
	border-color: #cee2ee;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

#forums_module #boards tr td:last-child,
#forums_module #topics tr td:last-child,
#forums_module #posts tr td:last-child,
#forums_module #topics th {
	border-color: #cee2ee;
}

#forums_module h2 {
	color: #3169b4;
	margin-top: 0px;
}

#forums_module #posts table td.actions a {
	height: auto;
}

#forums_module #topics tfoot tr th {
	padding-top: 1.5rem;
}

#forums_module.form.advanced-search .form_buttons {
	text-align: left;
}

@media (max-width: 991px) {
	#forums_module #topics tr td.poster_info,
	#forums_module #posts tr td.poster_info {
		padding-top: 1.5rem;
	}

	#forums_module #topics tr.moderator_actions td {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	#forums_module #posts tfoot tr td {
		padding-top: 1.5rem;
	}
}

/****************** FAQs *********************/
/***********************************************/
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

/******************** Glossary **********************/
/***************************************************/

/****************** Jobs ***********************/
/***********************************************/
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: #cee2ee;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.375rem 1rem;
}

/* sidebar job search filtering */
.right-side-col-wrapper .box #frmDepartments {
	margin-top: 0.75rem;
}

.right-side-col-wrapper .box #frmDepartments #Department {
	width: calc(100% - 55px);
}

.right-side-col-wrapper .box #frmDepartments button[type="submit"].primary {
	padding: 9px 10px !important;
	display: inline;
	float: right;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field input[type="tel"],
	#jobs_module.cms_form .cms_field input[type="email"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/************* Legilsative Districts ************/
/***********************************************/
#legislativedistricts_module.cms_list .cms_subtitle {
	margin-top: 0px;
	margin-bottom: 0.5rem;
	color: #3169b4;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
}

#legislativedistricts_module.cms_entity .cms_content h3 {
	margin-bottom: 0.5rem;
	color: #3169b4;
	font-size: 1.2rem;
	line-height: 1.5rem;
	font-weight: 700;
}

#legislativedistricts_module.cms_list .cms_list_item .cms_title h3 a {
	color: #3169b4;
}

@media (max-width: 991px) {
	#legislativedistricts_module.cms_entity .cms_content .contact_wrapper .legislator_image {
		display: inline-block;
		float: none;
		width: 100%;
		margin: 10px 0px 0px 0px;
	}
}

/******************** Links ************************/
/***************************************************/

/****************** Locations *******************/
/***********************************************/
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: #cee2ee;
}

/****************** Mailing Lists ************************/
/***************************************************/
#mailinglists-module.form .form_subheading {
	border-bottom: 1px solid #cee2ee;
	margin: 12px 0 20px;
}


/****************** News ***********************/
/***********************************************/
#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
	}
}

/****************** Newsletters ************************/
/***************************************************/
@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url("/core/public/shared/assets/images/required.gif");
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
		padding-bottom: 15px;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}

/****************** Partners ********************/
/***********************************************/
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 15px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/****************** Photo Albums ****************/
/***********************************************/
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/******************* Polls *********************/
/***********************************************/
#polls #pollResult .line {
	background-color: #3169b4;
	height: 4px;
}

/******************* Posts *********************/
/***********************************************/
.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #3169b4;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid #cee2ee;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

/* sidebar posts */
.inside-page-nested-right-column .box.posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe a,
.inside-page-nested-right-column .box.posts-blocks.posts-blocks-rss-feeds .posts-blocks-rss-feed a {
	border-bottom: 0px;
}

.inside-page-nested-right-column .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.inside-page-nested-right-column .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.inside-page-nested-right-column .box.posts-blocks.posts-blocks-categories .posts-blocks-category {
	padding-bottom: 7px;
}

.inside-page-nested-right-column .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.inside-page-nested-right-column .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 41px;
}

/********** Products (not e-commerce) **********/
/***********************************************/
#products_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#products_module.cms_entity .cms_content .image {
	margin-right: 15px;
	margin-left: 0px;
}

@media (max-width: 991px) {
	#products_module.cms_list .cms_list_item.left,
	#products_module.cms_list .cms_list_item.right {
		width: 100%;
		display: block;
		padding-left: 0px;
		padding-right: 0px;
	}

	#products_module.cms_list .cms_list_item .tile_description .image {
		float: none;
		display: block;
		padding-left: 0px;
	}

	#products_module.cms_list .cms_list_item .cms_title {
		padding: 0px;
	}

	#products_module.cms_list .cms_list_item.left tr td,
	#products_module.cms_list .cms_list_item.right tr td {
		border-bottom: 1px solid #cee2ee;
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}

	#products_module.cms_list .cms_item_divider {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
	}
}

/******************** Rates ********************/
/***********************************************/

/***************** Resources *******************/
/***********************************************/

/***************** Services *******************/
/***********************************************/
#services_module.list .list_item .title h3 {
	margin-top: 0px;
}

/*************** Staff Directory ***************/
/***********************************************/
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 150px;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/*************** Submission Forms ***************/
/***********************************************/
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .ui-datepicker-trigger {
	left: 5px;
	top: 5px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

/*form with payment*/
#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 27%;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
}

#submissionforms_module.cms_form #form-payment-info .s-payment-method-label {
	display: inline;
}

#submissionforms_module.cms_form #form-payment-info #payment-info .ui-form-panel {
	padding-top: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-fieldset:not(#payment-info) .ui-form-panel {
	padding-bottom: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-expiration-date-fields > div:first-of-type {
	margin-bottom: 10px;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-text-phone-extension {
	margin-top: 10px;
	margin-left: 0;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/*confirmation page*/
.module-forms.confirmation .step-title {
	margin-top: 1rem;
	color: #055a7f;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
}

.module-forms.confirmation .value-cell img.signature-image {
	max-width: 100%;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}

	.module-forms.confirmation .label-cell {
		padding-bottom: 0;
	}

	.module-forms.confirmation .value-cell {
		padding-top: 0;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_field.currency::before,
	.responsive #submissionforms_module.cms_form .cms_field.currency::before {
		top: 35%;
		left: 5px;
	}
}

/*************** Success Stories ***************/
/***********************************************/
#stories_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#stories_module.cms_list .cms_list_item .image {
	max-width: 200px;
}

@media (max-width: 991px) {
	#stories_module.cms_list .cms_list_item .image {
		padding: 0px 0px 10px 0px;
		display: block;
		float: none;
	}
}

/**************** Testimonials ******************/
/***********************************************/
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_list_item .cms_content .testimonial-link {
	border-bottom: 0px;
}

/******************* Videos ********************/
/***********************************************/
#video_module.cms_list .cms_list_item {
	border-bottom: 1px solid #cee2ee;
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
}

/**************** White Papers ******************/
/***********************************************/
#whitepapers_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

@media (max-width: 991px) {
	#whitepapers_module.cms_list .cms_list_item .image {
		padding: 0px 0px 10px 0px;
		display: block;
		float: none;
	}
}

/**************** Classifieds ******************/
/***********************************************/
.classifieds-collection .classifieds-node .classifieds-field h2 {
	margin-top: 0px;
}

/******************** Ideas *********************/
/***********************************************/
.ideas-collection .ideas-node .ideas-field-title h2 {
	margin-top: 0px;
}

/******************** Kudos *********************/
/***********************************************/
.kudos-collection .kudos-node .kudos-field-title h2 {
	margin-top: 0px;
}

.kudos-node .kudos-comments-title {
	background: #3169b4;
}

.kudos-node .kudos-comment-author {
	font-size: 0.8rem;
}

.kudos-node #kudos-comments .kudos-comment-form-required-info textarea {
	width: 100%;
}

/****************** Resources *****************/
/***********************************************/
.resource-collection .resource-option {
	background: none;
	padding: 0px;
	text-align: right;
}

.resource-collection .resource-option .select2-container--default {
	max-width: 100%;
}

.resource-collection .resource-option .select2-container--default .select2-selection--single {
	background-color: #fff;
	border: 1px solid #cccccc;
	border-radius: 0px;
}

.module-reservations .reservations-module .ui-form-input input[type="text"],
.module-reservations .reservations-module .ui-form-input select,
.module-reservations .reservations-module .ui-form-input textarea {
	max-width: 100%;
	padding: 8px 12px;
	font-size: 0.87rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	margin-left: 0px !important;
	border: 1px solid #cccccc;
}

.module-reservations .reservations-module .ui-form-label {
	margin-left: 0px !important;
}

.module-reservations .reservations-module .ui-form-legend-wrapper {
	width: 100%;
}

.module-reservations .reservations-module .ui-form-legend-wrapper .ui-form-legend {
	width: 100%;
	color: #ffffff;
	background: #3169b4;
	display: inline-block;
	height: auto;
	padding-top: 4px;
	padding-bottom: 4px;
}

@media (max-width: 991px) {
	.module-reservations .reservations-module .ui-form-input input[type="text"].hasDatepicker {
		width: calc(100% - 20px) !important;
	}

	.module-reservations .reservations-module .ui-datepicker-trigger {
		margin-top: 13px;
	}
}

/****************** eCommerce ************************/
/***************************************************/
#products_module.responsive a {
	border-bottom: 1px solid #cee2ee;
}

#products_module.responsive a:hover {
	border-bottom: 1px solid transparent;
}

#products_module.responsive a.middle-aligner {
	border-bottom: 0 none;
}

#products_module.responsive a.middle-aligner:hover {
	border-bottom: 0 none;
}

#products_module.responsive .category-header-image-wrap {
	padding-right: 1.5rem;
}

#products_module.responsive .category-header-column p {
	margin: 0;
}

#products_module.responsive .items.list-view {
	margin-top: 1.5rem;
}

#products_module.responsive .items.list-view .item-wrapper {
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
	border-bottom: 1px solid #cee2ee;
}

#products_module.responsive .items.list-view .item-wrapper .item {
	margin: 0;
}

#products_module.responsive .with-margin-bottom {
	margin-bottom: 0;
}

#products_module .pagination-wrapper a {
	border-bottom: 0px;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
	padding-right: 15px;
}

#products_module.responsive .availability_box .available {
	color: #3169b4;
	display: block;
}

#products_module.responsive .add_to_cart_form .contentbox_item br {
	display: none;
}

#products_module.responsive .contentbox_item .accepted-formats {
	word-break: break-all;
	line-height: 1rem;
}

#products_module.responsive .viewMoreAttributeOptions,
#products_module.responsive .hideMoreAttributeOptions {
	width: auto;
	display: inline-block;
}

#products_module.responsive .tab-item-content .product-review .product-review-author {
	font-size: 0.875rem;
}

#products_module.responsive .add_to_cart_form .cart-estimate-button {
	width: 100%;
}

#products_module.responsive .tab-item-content .product-review {
	border-bottom: 1px solid #cee2ee;
	margin-bottom: 1.5rem;
	margin-top: 0;
	padding-bottom: 1.5rem;
	padding-top: 0;
}

#products_module.responsive .tab-item-content .product-review:first-of-type {
	margin-top: 1.5rem;
}

#products_module.responsive .tab-item-content .product-review:last-of-type {
	border-bottom: 0 none;
	padding-bottom: 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-have-a-question {
	padding-top: 0;
	border-top: 0 none;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-answer {
	margin: 5px 0 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-answer p:last-of-type {
	margin-bottom: 0;
}

#products_module.responsive.cms_entity .related-items .related-items-title {
	color: #3169b4;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
}

#products_module.responsive .add_to_cart_form .quantity_row .product-quantity-label {
	width: 30%;
}

#products_module.responsive.cms_form .required-fields-information em,
#products_module.responsive.cms_form label em,
#products_module.responsive.cms_form .ui-form-label em,
#s-customer label em,
#s-checkout label em,
#s-checkout span em {
	color: #ff0000;
}

#products_module.responsive.cms_form .ui-form-label,
#products_module.responsive.cms_form .ui-form-field {
	line-height: 21px;
}

#products_module.responsive.cms_form .ui-form-buttons {
	margin-top: 15px;
}

#products_module.responsive.cms_entity .tab-item-content .product-questions {
	margin: 0 0 1.5rem 0;
	padding: 0 0 1.5rem 0;
	border-bottom: 1px solid #cee2ee;
}

#products_module.responsive.cms_entity .tab-item-content .product-questions:last-of-type {
	padding-bottom: 0;
	border-bottom: 0 none;
}

#products_module.responsive.cms_form .ui-form-label {
	height: auto;
}

#products_module.responsive.cms_form .ui-form-buttons button {
	width: auto;
}

#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label {
	font-weight: 400;
}

.cart-grid .s-row.s-odd,
#order-pad .s-row.s-odd,
#s-cart .s-row.s-odd,
#s-wishlist .s-row.s-odd,
#s-favorites .s-row.s-odd {
	border-bottom: 1px solid #cee2ee;
}

.cart-grid .s-row.s-header-row,
#order-pad .s-row.s-header-row,
#order-review .s-row.s-header-row,
#s-cart .s-row.s-header-row,
#s-wishlist .s-row.s-header-row,
#s-favorites .s-row.s-header-row {
	border-bottom: 1px solid #cee2ee;
}

#s-customer #s-sign-in .ui-form-label {
	height: auto;
}

#s-checkout .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
}

.fly-out-menu-container .fly-out-menu-button {
	border: 1px solid #ccc;
}

@media (max-width: 991px) {
	#products_module.responsive .items.list-view .item-wrapper .item .item-description {
		margin-left: 0;
		width: 100%;
	}

	#products_module.responsive .items.list-view .item-wrapper .item .item-image {
		float: none;
		margin-bottom: 10px;
	}

	#products_module.responsive.cms_form .ui-form-buttons {
		text-align: left;
	}

	#products_module.responsive.cms_form #ffIsRecommended {
		width: 100% !important;
	}

	#products_module.responsive.cms_form .ui-form-label {
		margin-bottom: 5px;
		overflow: visible;
	}

	#s-order-confirmation .ui-form-shipping-address-parent .columns,
	#s-order-confirmation .ui-data-payment .ui-data-payments,
	#s-order-confirmation .ui-data-payment .ui-data-addresses {
		width: 100%;
		float: none;
	}

	#products_module.responsive .add_to_cart_form .quantity_row .product-quantity-label {
		width: 100%;
	}
}



/****************** Medical ************************/
/***************************************************/
/* accordion search */
.physician-search-accordion .physician-search-wrap .physician-search-field .top-search-submit {
	min-width: 65%;
}

.physician-search-accordion .physician-search-wrap .physician-search-field .form-reset {
	font-size: 0.9rem;
}

.physician-search-accordion .physician-search-wrap .physician-search-field .bottom-search-submit {
	margin-right: 0px;
}

.physician-search-accordion .physician-search-field .bottom-search-submit {
	background-color: #3169b4 !important;
	margin: 0;
}

.physician-search-accordion .physician-search-field .bottom-search-submit:hover {
	background-color: #525252 !important;
}

/* physicians */
#physicians-module.physicians-collection .node.physician-node {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-bottom: 1px solid #cee2ee;
}

#physicians-module .field.field--phone .md-custom-heading.md-big-heading {
	font-size: 1.2rem;
}

#physicians-module.node .physician-profile-section,
#physicians-module .md-row.doctor-profile-section {
	border-top: 1px solid #cee2ee !important;
	margin-top: 1.5rem !important;
	padding-top: 1.5rem !important;
}

@media (max-width: 767px) {
	#physicians-module .md-physician-image {
		padding-right: 10px;
	}
}

/*clinics*/
#clinics-module.clinics-collection .md-location-list-h2 {
	margin-bottom: 0;
}

#clinics-module .facility-maps .link {
	display: inline-block;
}

@media (max-width: 479px) {
	#clinics-module .md-column-content-wrap {
		margin-bottom: 0 !important;
		padding-bottom: 0 !important;
	}
}

/*greeting cards*/
@media (max-width: 991px) {
	#greetingcards-module .greeting-card-form-preview-wrapper .ui-form-label {
		margin-bottom: 5px;
	}

	#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field input,
	#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field textarea {
		max-width: 100%;
	}

	#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field #field-roomNumber {
		width: 100% !important;
	}
}

/****************** Content Boxes *******************/
/***************************************************/
/* alerts */
#alerts_module.homepage_contentbox.list_alerts .alerts a:link,
#alerts_module.homepage_contentbox.list_alerts .alerts a:active,
#alerts_module.homepage_contentbox.list_alerts .alerts a:visited {
	font-size: 1rem;
	color: #3169b4;
}

#alerts_module.homepage_contentbox.list_alerts .date {
	font-size: 0.8rem;
	line-height: 0.9rem;
}

#alerts_module.homepage_contentbox.list_alerts .alerts a:hover {
	color: #aaa;
}

#alerts_module.homepage_contentbox.list_alerts .alerts {
	list-style-type: none;
	padding-left: 0px;
}

.slider-widget #alerts_module.homepage_contentbox.list_alerts {
	text-align: left;
	padding: 20px;
}

/* rss */
#feeds_module.homepage_contentbox .item {
	background-position: 0px 7px;
}

#feeds_module.homepage_contentbox .item .date-published {
	font-size: 0.8rem;
}

.slider-widget #feeds_module.homepage_contentbox {
	text-align: left;
	padding: 20px;
}

/* events */
#events_module.homepage_contentbox .event_list {
	margin-bottom: 12px;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	width: 100px;
}

#events_module.homepage_contentbox .event_list table tr td.title {
	width: calc(100% - 100px);
}

.slider-widget #events_module.homepage_contentbox {
	text-align: left;
	padding: 20px;
}

@media (max-width: 991px) {
	#events_module.homepage_contentbox .event_list table tr td.date,
	#events_module.homepage_contentbox .event_list table tr td.title {
		display: block;
		width: 100%;
	}
}

/* files */
.cb-boxes .files-collection .files-node {
	padding: 0px;
	margin: 0px 0px 7px 0px;
}

.slider-widget .files-collection {
	text-align: left;
	padding: 20px;
}

/* forums */
#forums_module.homepage_contentbox .cms_list_item {
	margin-top: 1.5rem;
	padding-bottom: 1.5rem;
	border-bottom: 1px solid #cee2ee;
}

.slider-widget #forums_module.homepage_contentbox {
	text-align: left;
	padding: 20px;
}

/* legislative districts */
#legislativedistricts_module.homepage_contentbox .cms_list .cms_subtitle {
	margin-top: 0px;
	margin-bottom: 0px;
	color: #3169b4;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
	padding: 0px;
}

.slider-widget #legislativedistricts_module.homepage_contentbox {
	text-align: left;
	padding: 20px;
}

/* links */

/* news  */
#news_module.homepage_contentbox .cms_title h3 {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
}

#news_module.homepage_contentbox .cms_date h3 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	color: #474848;
	font-size: 0.8rem;
	line-height: 1.1rem;
	font-weight: 400;
}

#news_module.homepage_contentbox .cms_description p {
	margin-bottom: 1rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

#news_module.homepage_contentbox > a {
	display: none;
}

#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 20px;
}

.slider-widget #news_module.homepage_contentbox {
	text-align: left;
	padding: 20px;
}

/* newsletter */

/* partners */
#partners_module.homepage_contentbox.displaymode_list .cms_list_item {
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid #cee2ee;
}

.slider-widget #partners_module.homepage_contentbox.displaymode_list {
	text-align: left;
	padding: 20px;
}

/* polls */

/* rates */

/* testimonials */
#testimonials_module_contentbox.contentbox_item .company,
#testimonials_module_contentbox.contentbox_item .author-name {
	font-weight: bold;
}

#testimonials_module_contentbox .w-slide-testimonial {
	height: auto;
	margin-bottom: 30px;
}

.slider-widget #testimonials_module_contentbox.contentbox_item {
	text-align: left;
	margin: 20px;
}

/* videos */
.homepage-featured-video {
	margin-top: 10px;
	margin-bottom: 10px;
}

.slider-widget .homepage-featured-video {
	text-align: left;
	margin: 20px;
}

/* classified */
.cb-boxes .classifieds-collection .classifieds-node {
	padding: 0px;
	margin: 0px 0px 10px 0px;
}

.slider-widget .classifieds-collection {
	text-align: left;
	padding: 20px;
}

/* ideas */
.cb-boxes .ideas-collection .ideas-node {
	padding: 0px;
	margin: 0px 0px 10px 0px;
}

.slider-widget .ideas-collection {
	text-align: left;
	padding: 20px;
}

/* kudos */
.cb-boxes .kudos-collection .kudos-node {
	padding: 0px;
	margin: 0px 0px 10px 0px;
}

.slider-widget .kudos-collection {
	text-align: left;
	padding: 20px;
}

/* practise areas */
#module-practiceareas.homepage_contentbox.slideshow .content-box-image-link {
	background-size: auto;
}

.slider-widget #module-practiceareas.homepage_contentbox.slideshow {
	text-align: left;
	margin: 20px;
}

/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	padding: 10px;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	font-size: 0.87rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox input.hasDatepicker[type="text"] {
	width: calc(100% - 40px);
	float: left;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	left: 0;
	margin-left: 4px;
	margin-top: 0;
	position: relative;
	top: 8px;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	color: inherit;
}

/* ecommerce  */
#products_module.homepage_contentbox.displaymode_category_list .links {
	border-bottom: 1px solid #cee2ee;
	margin-bottom: 10px;
	padding-bottom: 20px;
}

#products_module.homepage_contentbox.displaymode_list .cms_list_item_grid_container {
	width: 100%;
	display: block;
	border-bottom: 1px solid #cee2ee;
	margin-bottom: 20px;
	padding-bottom: 20px;
}

#products_module.homepage_contentbox.displaymode_list .cms_list_item_grid_container .name {
	margin-top: 5px;
}

#products_module.featured_list .cycle-slide > div {
	padding-top: 0px !important;
}

.slider-widget #products_module.homepage_contentbox {
	text-align: left;
	padding: 20px;
}
